import React, { useState } from "react";
import "./headerMenuModal.scss";
import CloseIcon from "../../assets/icons/Close.svg";
import UserIcon from "../../assets/icons/user.svg";
import AnalyticsIcon from "../../assets/icons/Analytics-icon.svg";
import MultipleIcon from "../../assets/icons/multiple-user.svg";
import RevenueIcon from "../../assets/icons/revenue.svg";
import BookmarkIcon from "../../assets/icons/bookmark.svg";
import PromoteIcon from "../../assets/icons/promote-icon.svg";
import LogOutIcon from "../../assets/icons/log-out.svg";
import { logout } from "../../utils/auth.util";
import { ApiGet } from "../../helpers/API/ApiData";
import AccountManagers from "../modals/accountManagers";
import UserModal from "../modals/userModal";
import PromoteModal from "../modals/promote";
import OldUserData from "../modals/userModal/oldUserData";

export default function HeaderMenuModal(props) {
  const {
    sidebarMenu,
    revenueModalOpen,
    handlePaymentData,
    setRevenueModalOpen,
    couponCodeModalOpen,
    setCouponCodeModalOpen,
    setSidebarMenu,
    analyticsModalOpen,
    setAnalyticsModalOpen,
  } = props;

  const [accountManagersModal, setAccountManagersModal] = useState(false);
  const [newAccountManager, setNewAccountManager] = useState(false);
  const [addValidityModal, setAddValidityModal] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userInformationModalOpen, setUserInformationModalOpen] = useState(false);
  const [promoteRequestsModal, setPromoteRequestsModal] = useState(false);
  const [allUserModalOpen, setAllUserModalOpen] = useState(false);

  return (
    <>
      <div>
        <>{sidebarMenu && <div className="header-menu-blur"></div>}</>
        <div
          className={sidebarMenu ? "header-menu-sidebar-design header-menu-sidebar-show" : "header-menu-sidebar-hidden header-menu-sidebar-design"}
        >
          <div className="modal-header-alignment">
            <div onClick={() => setSidebarMenu(false)}>
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
          <div className="modal-body">
            <div
              className="menu-design"
              onClick={() => {
                setAddUserModalOpen(!addUserModalOpen);
              }}
            >
              <div>
                <img src={UserIcon} alt="UserIcon" />
              </div>
              <div>
                <span>Users</span>
              </div>
            </div>
            {/* <div className="menu-design" onClick={() => {setAllUserModalOpen(!allUserModalOpen)}}>
              <div>
                <img src={UserIcon} alt="UserIcon" />
              </div>
              <div>
                <span>All Users</span>
              </div>
            </div> */}
            <div className="menu-design" onClick={() => setAccountManagersModal(!accountManagersModal)}>
              <div>
                <img src={MultipleIcon} alt="MultipleIcon" />
              </div>
              <div>
                <span>Account managers</span>
              </div>
            </div>
            <div className="menu-design" onClick={() => setRevenueModalOpen(!revenueModalOpen)}>
              <div>
                <img src={RevenueIcon} alt="RevenueIcon" />
              </div>
              <div>
                <span>Revenue</span>
              </div>
            </div>
            <div className="menu-design" onClick={() => setAnalyticsModalOpen(!analyticsModalOpen)}>
              <div>
                <img src={AnalyticsIcon} alt="AnalyticsIcon" />
              </div>
              <div>
                <span>Analytics</span>
              </div>
            </div>
            <div className="menu-design" onClick={() => setCouponCodeModalOpen(!couponCodeModalOpen)}>
              <div>
                <img src={BookmarkIcon} alt="BookmarkIcon" />
              </div>
              <div>
                <span>Coupon codes</span>
              </div>
            </div>
            <div className="menu-design" onClick={() => setPromoteRequestsModal(!promoteRequestsModal)}>
              <div>
                <img src={PromoteIcon} alt="BookmarkIcon" />
              </div>
              <div>
                <span>Promote</span>
              </div>
            </div>
            {/* <div className="menu-design" onClick={handlePaymentData}>
              <div>
                <img src={BookmarkIcon} alt="BookmarkIcon" />
              </div>
              <div>
                <span>Coupon codes</span>
              </div>
            </div> */}
            <div className="menu-design" onClick={() => logout()}>
              <div>
                <img src={LogOutIcon} alt="LogOutIcon" />
              </div>
              <div>
                <span>Logout</span>
              </div>
            </div>
            {/* as */}
          </div>
        </div>
      </div>
      {accountManagersModal && (
        <AccountManagers
          accountManagersModal={accountManagersModal}
          setAccountManagersModal={setAccountManagersModal}
          setNewAccountManager={setNewAccountManager}
          newAccountManager={newAccountManager}
        />
      )}
      {addUserModalOpen && (
        <UserModal
          setAddUserModalOpen={setAddUserModalOpen}
          setAddValidityModal={setAddValidityModal}
          setUserInformationModalOpen={setUserInformationModalOpen}
          addUserModalOpen={addUserModalOpen}
          userInformationModalOpen={userInformationModalOpen}
          addValidityModal={addValidityModal}
          allUserModalOpen={allUserModalOpen}
        />
      )}
      {promoteRequestsModal && <PromoteModal promoteRequestsModal={promoteRequestsModal} setPromoteRequestsModal={setPromoteRequestsModal} />}
      {allUserModalOpen && <OldUserData setAllUserModalOpen={setAllUserModalOpen} />}
    </>
  );
}
